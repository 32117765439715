<template>
  <div class="content">
    <!-- 日志详情 -->
    <div class="logBox">
      <!-- 标题 -->
      <div class="titleBox">
        <div class="oneRow">帮扶党员： {{ logDetail.supportedName }}</div>
        <div class="twoRow">
          <div>党员：{{ logDetail.supportName }}</div>
          <div>提交日期：{{ logDetail.createTime.split(' ')[0] }}</div>
        </div>
      </div>
      <!-- 文字 -->
      <div class="textBox">{{ logDetail.content }}</div>
      <!-- 图片 -->
      <div class="imgBox" v-if="logDetail.images">
        <img
          v-for="(item, index) in logDetail.images.split(',')"
          :key="index"
          :src="item"
        />
      </div>
    </div>
    <!-- 空隙 -->
    <div class="gap"></div>
    <!-- 审核 -->
    <div class="auditBox">
      <!-- 状态 -->
      <div>
        <div class="left">状态</div>
        <div class="right">{{ logDetail.statusStr }}</div>
      </div>
      <!-- 积分 -->
      <div>
        <div class="left">积分</div>
        <div class="right" v-if="logDetail.status">
          {{ logDetail.supportIntegral }}
        </div>
        <div class="right" v-else>-</div>
      </div>
    </div>
    <div v-if="logDetail.remarks">
      <!-- 空隙 -->
      <div class="gap"></div>
      <!-- 回复 -->
      <div class="auditBox">
        <div>
          <div class="left">回复</div>
          <div class="right">{{ logDetail.remarks }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { requestLogDetail } from '@/api/pairSupport';
export default {
  data() {
    return {
      logDetail: {},
    };
  },
  created() {
    this.getLogDetail();
  },
  methods: {
    // 获取日志详情
    getLogDetail() {
      requestLogDetail({id:this.$route.params.id}).then((res) => {
        if (res.success) {
          this.logDetail = res.data;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
$px: 1rem/75;
.content {
  width: 100%;
  height: 100%;
  .gap {
    width: 100%;
    height: 24 * $px;
    background: #f5f5f5;
  }
  .logBox {
    padding: 32 * $px;
    color: #333;
    .titleBox {
      border-bottom: 1 * $px solid #eee;
      .oneRow {
        font-size: 36 * $px;
        font-weight: 500;
      }
      .twoRow {
        display: flex;
        justify-content: space-between;
        font-size: 28 * $px;
        padding: 30 * $px 0;
      }
    }
    .textBox {
      font-size: 28 * $px;
      padding: 32 * $px 0;
      text-indent: 1em;
    }
    .imgBox {
      display: flex;
      justify-content: space-between;
      > img {
        width: 33%;
        height: 200 * $px;
      }
    }
  }
  .auditBox {
    padding: 32 * $px;
    font-size: 28 * $px;
    > div {
      display: flex;
      margin-bottom: 20 * $px;
    }
    .left {
      color: #999;
      margin-right: 8 * $px;
      white-space: nowrap;
    }
    .right {
      color: #333;
    }
  }
}
</style>
